import React from 'react'
import instagramLogo from '../../assets/img/instagram-logo-transparent.png'

const InstagramButton = () => {
    const style = {
        container: {
            display: 'flex',
        },
        button: {
          backgroundColor: '#458eff',
          borderRadius: 8,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          paddingTop: 8,
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 8,
          color: 'white',
        },
        logo: {
          display: 'flex',
          height: 40,
        }
      }
    return (
        <div style={style.container}>
            <a
            style={style.button}
            href="https://instagram.com/veridbe.rs/"
            target="_blank"
            rel="noopener noreferrer"
            >
            Veridbe.rs&nbsp;
            <img style={style.logo} src={instagramLogo} alt="logo" />
            </a>
        </div>
      )
}

export default InstagramButton;
