import './App.css';
import NavigationBar from './components/NavigationBar';
import InstagramButton from './components/InstagramButton';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <NavigationBar />
      <div className="content">
        <span>Sajt je trenutno u izradi.</span>
        <span>Pratite naš instagram profil</span>
        <span><InstagramButton /></span>
      </div>
      <Footer />
    </div>
  );
}

export default App;
