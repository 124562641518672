import React from 'react'
import './style.css'
import '../../assets/fonts/Ubuntu/Ubuntu-Regular.ttf';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const style = {
  navTitle: {
    fontSize: 40,
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    float: 'left',
    paddingLeft: 20,
    paddingTop: 8,
  },
  random: {
    fontSize: 40,
  }
}

const NavigationBar = () => {

  const startProposalWizard = () => {
    console.log("Proposal Wizard: Starting...");
  }

  return (
    <nav>
      <div style={style.navTitle}>Veridbe.rs</div>
      <button onClick={startProposalWizard} className="proposalWizardButtonContainer">
        <span className="proposalWizardButtonText" >Organizuj veridbu</span>
        <span className="proposalWizardButtonIcon">
          <VolunteerActivismIcon fontSize="large"/>
        </span>
      </button>
    </nav>
  )
}

export default NavigationBar;