import React from 'react'
import './style.css'

const Footer = () => {
  const today = new Date();
  return (
    <footer>
        <div className='footerContent'>&copy; {today.getFullYear()} - Veridbe.rs</div>
    </footer>
  )
}

export default Footer;